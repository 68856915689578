import { useDispatch, useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu'
import PowerIcon from '@mui/icons-material/PowerSettingsNew';
import RemoteKey from './controls/RemoteKey';
import Box from '@mui/material/Box'
import { openMenu } from '../app/reducers';
import { topBarHeight } from '../styles/theme';
import { useSendKeyMutation } from '../app/api';
import debounce from 'lodash.debounce';
import logo from '../assets/worldvue_banner_white.svg';
import ReactGA from 'react-ga4';

const appbarStyle =(theme)=>({
   'backgroundColor': 'rgba(0,0,0,0)',
   'width': `calc(100% - ${260}px)`,
   'boxShadow': 'none',
   'height': topBarHeight,
   'justifyContent': 'flex-end',
   // 'borderBottom': '1px solid black',
   [theme.breakpoints.down('md')]:{
      width: '100%'
   }
})
const TopNavBar = (props)=>{
   const dispatch = useDispatch();
   const menuOpened = useSelector((state)=>{
      return state.root.menuOpened
   }) 
   const serialNumber = useSelector((state)=>{
      return state.root.activeStb;
   })
   const [sendKey] = useSendKeyMutation();
   const handleButtonPress = debounce((key, event)=>{
      ReactGA.event({
         category: 'button_press',
         action: key+' Press'
       });
      sendKey({key:key, serialNumber:serialNumber})
   }, 120)

   return <AppBar sx={appbarStyle} position='fixed'>
      <Toolbar variant='dense' sx={{'justifyContent': 'space-between'}}>
         <MenuIcon onClick ={()=>{
            dispatch(openMenu(!menuOpened))
         }}/>
         <Box style={{flexGrow:1, display:'flex', justifyContent: 'center'}}>
            <img style={{'maxHeight':'35px'}} src={logo} alt='worldvue logo'/>
         </Box>
         <RemoteKey buttonKey='POWER' onKeyPress={handleButtonPress} sx={{'padding': '0px'}}>
            <PowerIcon/>
         </RemoteKey>
      </Toolbar>
   </AppBar>
}

export default TopNavBar;
